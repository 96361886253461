import Vue from 'vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery'
import 'bootstrap/dist/js/bootstrap.js'
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import store from './store/index.js';
import { mapGetters } from 'vuex';
import * as Sentry from "@sentry/vue";

Sentry.init({
	Vue: Vue,
	dsn: "https://fa9748f939b8b248a06b36b264fde049@o4505835419074560.ingest.sentry.io/4505845636726784",
});

Vue.config.productionTip = false
Vue.use(VueGoodTablePlugin);

Vue.mixin({
	data()
	{
		return {
			required_permissions: [],
			search_params: {
				q: "",
				columnFilters: {},
				sort: [],
				page: 1,
				perPage: 25
			},
			api_server: {
				"goliathlms.com": "https://api.goliathlms.com",
				"goliath.nerivon.cloud": "https://api.goliath.nerivon.cloud",
				"local.goliath.mitchinsurance.com": "https://local.goliath.mitchinsurance.com:8888"
			}[window.location.hostname.indexOf("goliathlms.com") == -1 ? window.location.hostname : "goliathlms.com"],
			api_server_no_cf: {
				"goliathlms.com": "https://api2.goliathlms.com",
				"goliath.nerivon.cloud": "https://api.goliath.nerivon.cloud",
				"local.goliath.mitchinsurance.com": "https://local.goliath.mitchinsurance.com:8888"
			}[window.location.hostname.indexOf("goliathlms.com") == -1 ? window.location.hostname : "goliathlms.com"],
			vgt_reload_count: 0,
			mermaid_config: {
				maxTextSize: 1000000,
			}
		}
	},
	computed:
	{
		canSetAccount()
		{
			return this.userHasAction(this.user, ['IDDQD']);
		},
		isAdmin()
		{
			return this.userHasAction(this.user, ['ADMIN']);
		},
		vgtTheme()
		{
			try
			{
				if(this.user.theme == "dark")
				{
					return "nocturnal";
				}
			}
			catch(e)
			{
				// pass
			}

			// Light mode uses default theme.
			return "";
		},
		colours()
		{
			try
			{
				if(this.user.theme == "dark")
				{
					return "text-light bg-secondary";
				}
			}
			catch(e)
			{
				// pass
			}

			// Light mode uses default styles.
			return "";
		},
		colours2()
		{
			try
			{
				if(this.user.theme == "dark")
				{
					return "text-light bg-dark";
				}
			}
			catch(e)
			{
				// pass
			}

			// Light mode uses default styles.
			return "";
		},
		...mapGetters([
			"user"
		])
	},
	methods:
	{
		CORS(request_type, request_url, request_data, success_callback, fail_callback)
		{
			jQuery.ajax(
			{
				type: request_type,
				// url: "https://api.goliath.mitchellwhale.com" + request_url,
				url: this.api_server + request_url,
				data: request_data,
				xhrFields: {
					withCredentials: true
				},
				crossDomain: true,
				beforeSend: (xhr) => {
					xhr.setRequestHeader("X-CSRF-TOKEN", this.getCookie("csrf_access_token"));
				}
			}).done((data) =>
			{
				if(typeof success_callback == "function")
				{
					success_callback(data);
				}
			}).fail((jqXHR) =>
			{
				if(typeof fail_callback == "function")
				{
					fail_callback(jqXHR);
				}

				if(jqXHR.status == 401)
				{
					this.$store.commit("logged_in", false);

					if(this.$route.path != "/login")
					{
						this.$router.push("/login").catch(() => { /* ignore error */ });
					}

					if(request_url != "/totp/verify")
					{
						this.$store.commit("resetLoading");

						return;
					}
				}
			});
		},
		CORS2(request_type, request_url, request_data, success_callback, fail_callback)
		{
			jQuery.ajax(
			{
				type: request_type,
				// url: "https://api.goliath.mitchellwhale.com" + request_url,
				url: this.api_server + request_url,
				data: request_data,
				xhrFields: {
					withCredentials: true
				},
				crossDomain: true,
				beforeSend: (xhr) => {
					xhr.setRequestHeader("X-CSRF-TOKEN", this.getCookie("csrf_access_token"));
				},
				processData: false,  // tell jQuery not to process the data
				contentType: false,  // tell jQuery not to set contentType
			}).done((data) =>
			{
				if(typeof success_callback == "function")
				{
					success_callback(data);
				}
			}).fail((jqXHR) =>
			{
				if(typeof fail_callback == "function")
				{
					fail_callback(jqXHR);
				}

				if(jqXHR.status == 401)
				{
					this.$store.commit("logged_in", false);

					if(this.$route.path != "/login")
					{
						this.$router.push("/login").catch(() => { /* ignore error */ });
					}

					if(request_url != "/totp/verify")
					{
						this.$store.commit("resetLoading");

						return;
					}
				}
			});
		},
		setCookie(cname,cvalue,exdays)
		{
			var d = new Date();
			d.setTime(d.getTime()+(exdays*24*60*60*1000));
			var expires = "expires="+d.toGMTString();
			document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/;SameSite=Strict";
		},
		getCookie(cname)
		{
			var name = cname + "=";
			var ca = document.cookie.split(';');
			for(var i=0; i<ca.length; i++)
			{
				var c = ca[i].trim();
				if (c.indexOf(name)==0)
					return c.substring(name.length,c.length);
			}
			return "";
		},
		retina(src)
		{
			if(window.devicePixelRatio == 2)
			{
				return src.replace(/\.(png|PNG|jpe?g|JPE?G)$/, "@2x.$1");
			}
			else
			{
				return src;
			}
		},
		close()
		{
			this.$router.go(-1);
		},
		closeModals()
		{
			jQuery(".modal").each(function()
			{
				var me = jQuery(this);

				if(me.hasClass("fade"))
				{
					me.removeClass("fade");
					me.modal("hide");
					me.addClass("fade");
				}
				else
				{
					me.modal("hide");
				}
			});
		},
		nerivon_confirm(title, message, type, close_after, callback)
		{
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			if(type == "warning")
			{
				icon = '<i class="fa-regular fa-exclamation-circle text-warning"></i>';
			}
			else if(type == "error")
			{
				icon = '<i class="fa-regular fa-exclamation-circle text-danger"></i>';
			}
			else if(type == "success")
			{
				icon = '<i class="fa-regular fa-check-circle text-success"></i>';
			}
			else if(type == "info")
			{
				icon = '<i class="fa-regular fa-info-circle text-info"></i>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal" data-backdrop="static" data-keyboard="false" aria-hidden="true"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Yes</button> \
							<button type="button" class="btn btn-light" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 0">No</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', () =>
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', () =>
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				// Make sure all modals are closed. Sometimes the backdrop gets stuck.
				this.closeModals();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivon_alert(title, message, type, close_after, callback)
		{
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function(isConfirm)
				{
					return isConfirm;
				};
			}

			if(type == "warning")
			{
				icon = '<i class="fa-regular fa-exclamation-circle text-warning"></i>';
			}
			else if(type == "error")
			{
				icon = '<i class="fa-regular fa-exclamation-circle text-danger"></i>';
			}
			else if(type == "success")
			{
				icon = '<i class="fa-regular fa-check-circle text-success"></i>';
			}
			else if(type == "info")
			{
				icon = '<i class="fa-regular fa-info-circle text-info"></i>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal" data-backdrop="static" data-keyboard="false" aria-hidden="true"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', () =>
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', () =>
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivon_input(title, message, close_after, callback)
		{
			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal" data-backdrop="static" data-keyboard="false" aria-hidden="true"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title"><i class="fa-regular fa-keyboard"></i> ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '<br><br><input id="nvModalInput" class="form-control" :class="colours2" autofocus /></div> \
						<div class="modal-footer text-center"> \
							<button type="button" class="btn btn-light" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 0">Cancel</button> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = document.getElementById(\'nvModalInput\').value">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', () =>
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', () =>
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 0 ? false : nvModalAnswer));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivon_choice(title, message, type, choices, close_after, callback)
		{
			var icon = "";
			var choices_html = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			if(type == "warning")
			{
				icon = '<i class="fa-regular fa-exclamation-circle text-warning"></i>';
			}
			else if(type == "error")
			{
				icon = '<i class="fa-regular fa-exclamation-circle text-danger"></i>';
			}
			else if(type == "success")
			{
				icon = '<i class="fa-regular fa-check-circle text-success"></i>';
			}
			else if(type == "info")
			{
				icon = '<i class="fa-regular fa-info-circle text-info"></i>';
			}

			for(var i=0; i<choices.length; i++)
			{
				choices_html += '<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = \'' + choices[i][0] + '\'">' + choices[i][1] + '</button>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal" data-backdrop="static" data-keyboard="false" aria-hidden="true"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" />' + choices_html + ' \
							<button type="button" class="btn btn-light" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = \'\'">Cancel</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', () =>
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', () =>
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback(nvModalAnswer);
			});

			jQuery("#nvModal").modal("show");
		},
		showWarning(message, text, close_after, callback)
		{
			this.nerivon_confirm(message, text, "warning", close_after, callback);
		},
		showError(message, text, close_after, callback)
		{
			// If the text looks like JSON, lets try parsing it.
			if(String(text).charAt(0) == "{") {
				try {
					let obj = JSON.parse(text);
					// If that didn't fail, lets update the text with any of the possible error fields.
					if(obj.msg != undefined)
					{
						text = obj.msg;
					}
					else if(obj.error != undefined)
					{
						text = obj.error;
					}
				}
				catch {
					// Ignore. Text was text.
				}
			}

			this.nerivon_alert(message, text, "error", close_after, callback);
		},
		showSuccess(message, text, close_after, callback)
		{
			this.nerivon_alert(message, text, "success", close_after, callback);
		},
		showInfo(message, text, close_after, callback)
		{
			this.nerivon_alert(message, text, "info", close_after, callback);
		},
		accountHasFeature(account, features)
		{
			if(account == null || typeof account.feature_ids == "undefined" || account.feature_ids == null)
			{
				return false;
			}

			for(var i=0; i<account.feature_ids.length; i++)
			{
				for(var j=0; j<features.length; j++)
				{
					if(account.feature_ids[i] == features[j])
					{
						console.debug(features[j])
						return true;
					}
				}
			}

			return false;
		},
		userHasAction(user, actions)
		{
			if(user == null || typeof user.actions == "undefined" || user.actions == null)
			{
				return false;
			}

			// Always include IDDQD in actions. If the user has IDDQD they can do anything.
			actions.push("IDDQD");

			for(var i=0; i<user.actions.length; i++)
			{
				for(var j=0; j<actions.length; j++)
				{
					if(user.actions[i].constant == actions[j])
					{
						return true;
					}
				}
			}

			return false;
		},
		copy: function(id, value)
		{
			const node = document.getElementById(id);
			const original_value = node.innerHTML;

			// If we want to copy a specific value, stick it in the ID given.
			if(value)
			{
				// If we're given a phone number to copy, remove the "+1." from it.
				if(String(id).indexOf("phone") !== -1 && String(value).indexOf("+1.") !== -1)
				{
					value = String(value).replace("+1.", "");
				}

				node.innerHTML = value;
			}

			if(document.body.createTextRange)
			{
				const range = document.body.createTextRange();
				range.moveToElementText(node);
				range.select();
			}
			else if (window.getSelection)
			{
				const selection = window.getSelection();
				const range = document.createRange();
				range.selectNodeContents(node);
				selection.removeAllRanges();
				selection.addRange(range);
			}
			else
			{
				return;
			}

			document.execCommand("copy");

			if(window.getSelection)
			{
				window.getSelection().removeAllRanges();
			}
				else if(document.selection)
			{
				document.selection.empty();
			}

			// If we were given a specific value to copy, restore the original value.
			if(value)
			{
				node.innerHTML = original_value;
			}

			jQuery("#copy_" + id).removeClass("fa-clipboard-list").addClass("fa-check-circle text-success");

			setTimeout(function()
			{
				jQuery("#copy_" + id).removeClass("fa-check-circle text-success").addClass("fa-clipboard-list");
			}, 1000);
		},
		updateParams(newProps)
		{
			this.search_params = Object.assign({}, this.search_params, newProps);
		},
		onPageChange(params)
		{
			this.updateParams({page: params.currentPage});
			this.load();
		},
		onPerPageChange(params)
		{
			this.updateParams({perPage: params.currentPerPage});
			this.load();
		},
		onSortChange(params)
		{
			this.updateParams({sort: params});
			this.load();
		},
		onColumnFilter(params)
		{
			this.updateParams(params);
			this.load();
		},
		number_format(number, decimals, dec_point, thousands_sep)
		{
			// http://kevin.vanzonneveld.net
			// Original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
			// Strip all characters but numerical ones.
			number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
			var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			s = '',
			toFixedFix = function (n, prec) {
				var k = Math.pow(10, prec);
				return '' + Math.round(n * k) / k;
			};
			// Fix for IE parseFloat(0.55).toFixed(0) = 0;
			s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
			if (s[0].length > 3) {
				s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
			}
			if ((s[1] || '').length < prec) {
				s[1] = s[1] || '';
				s[1] += new Array(prec - s[1].length + 1).join('0');
			}
			return s.join(dec);
		},
		outgoingCall(to, customer_id)
		{
			// Prevent a race condition where a user gets a call while initiating an outbound call.
			// This will silence any incoming calls at the UI level.
			const initial_available = this.user.available;
			this.user.available = false;

			to = this.phoneDisplay(to);

			if(this.nerivon_confirm("Call " + to, "You're about to initiate a call to " + to + ". Continue?", "info", true, (confirmed) =>
			{
				if(confirmed)
				{
					this.CORS("POST", "/calls/outbound", JSON.stringify({"to_number": to, "customer_id": customer_id}), () =>
					{
						// All's well, pass.
					},
					(response) =>
					{
						this.showError("Error Calling " + to, response.responseText, true, null);
						this.user.available = initial_available;
					});
				}
				else
				{
					this.user.available = initial_available;
				}
			}));
		},
		phoneDisplay(input)
		{
			var value = String(input)
			// var plusone = false;

			if(value.indexOf("+1.") !== false)
			{
				// plusone = true;
				value = value.replace("+1.", "");
			}

			if(value.indexOf("+1") !== false)
			{
				// plusone = true;
				value = value.replace("+1", "");
			}

			value = value.replace(/[^0-9]/g, '');
			var length = value.length;

			if(length == 3)
			{
				// Leave value as-is.
			}
			else if(length <= 6)
			{
				value = value.replace(/([0-9]{3}?)([0-9]{1,3}?)/g, '$1-$2')
			}
			else if(length <= 10)
			{
				value = value.replace(/([0-9]{3}?)([0-9]{3}?)([0-9]{1,4}?)?/g, '$1-$2-$3')
			}
			else
			{
				value = value.replace(/([0-9]{3}?)([0-9]{3}?)([0-9]{4}?)([0-9]{1,5}?)?/g, '$1-$2-$3 x$4')
			}

			return value;
		},
		uniqueArray(value, index, self)
		{
			return self.indexOf(value) === index;
		},
		monthName(month_index)
		{
			return long_months[month_index];
		},
		lastCommentTooltip(comment)
		{
			if(typeof comment == "undefined" || comment == null)
			{
				return "";
			}

			return comment.replace(/\|/g, "<br>").replace(/\n/g, "<br><br>");
		},
		lastCommentTable(comment)
		{
			if(typeof comment == "undefined" || comment == null)
			{
				return "";
			}

			return "A | B\n--- | ---\n" + comment.replace(/\|/g, " | ");
		},
		restoreFilters(key)
		{
			// Load search filters and modify data() before VGT reads the columns.
			const filters = localStorage.getItem(key);

			if(filters == null)
			{
				return;
			}

			// Save the Vue's original values in case things go wrong.
			const original_params = this.search_params;
			let original_filters = null;

			try
			{
				original_filters = this.active_filters;
			}
			catch(e)
			{
				original_filters = null;
			}

			const original_sort = this.initial_sort;
			const original_page = this.initial_page;

			try
			{
				this.search_params = JSON.parse(filters);
			}
			catch(e)
			{
				this.search_params = original_params;
			}

			try
			{
				this.active_filters = this.search_params.filters;
			}
			catch(e)
			{
				this.active_filters = original_filters;
			}

			try
			{
				if(this.search_params.sort.length > 0)
				{
					this.initial_sort = this.search_params.sort;
				}
			}
			catch(e)
			{
				this.initial_sort = original_sort;
			}

			try
			{
				if(this.search_params.page)
				{
					this.initial_page = this.search_params.page;
				}
			}
			catch(e)
			{
				this.initial_page = original_page;
			}

			for(let key in this.search_params.columnFilters)
			{
				for(let i=0; i<this.columns.length; i++)
				{
					if(this.columns[i].field == key)
					{
						try
						{
							this.columns[i].filterOptions.filterValue = this.search_params.columnFilters[key];
						}
						catch(e)
						{
							console.error(e);
						}
					}
				}
			}
		},
		clearFilters(key)
		{
			// Clear the saved filters.
			localStorage.removeItem(key);

			// Clear column filters.
			jQuery("[name*='vgt-']").val("").trigger("change");

			for(let i=0; i<this.columns.length; i++)
			{
				try
				{
					this.columns[i].filterOptions.filterValue = "";
				}
				catch(e)
				{
					// This is fine. Some screens don't use it.
				}
			}

			// Reset everything back to defaults.
			this.search_params = {
				q: "",
				columnFilters: {},
				sort: this.initial_sort,
				page: 1,
				perPage: 25
			};
			this.active_filters = {
				lead_source_ids: [],
				lead_status_ids: [],
				user_ids: [],
				date_start: null,
				date_end: null,
				date_field: "created",
				active: null
			};

			try
			{
				// On some screens, allow the date preset to be restored.
				this.setDate();
			}
			catch(e)
			{
				// Pass, function doesn't exist.
			}

			this.vgt_reload_count++;

			// If there's a populateFilters function, call it.
			if(typeof this.populateFilters != "undefined")
			{
				this.$nextTick(() => {this.populateFilters()});
			}
		},
		terminateCall(sip_call_id, callback)
		{
			if(this.nerivon_confirm("Are you sure?", "This call will be TERMINATED.", "warning", true, (c1) =>
			{
				if(c1)
				{
					if(this.nerivon_confirm("Are you REALLY sure?", "You can't undo this.", "warning", true, (c2) =>
					{
						if(c2)
						{
							this.$store.commit("startLoading");

							this.CORS("DELETE", "/calls/" + sip_call_id, null,
							() =>
							{
								this.$store.commit("stopLoading");

								if(typeof callback == "function")
								{
									callback();
								}
							},
							(response) =>
							{
								this.showError("Error Ending Call", response.responseText, true, null);
								this.$store.commit("stopLoading");
							})
						}
					}));
				}
			}));
		},
		sumField(rowObj, f)
		{
			let sum = 0;
			for (let i = 0; i < rowObj.children.length; i++) {
				sum += Math.abs(rowObj.children[i][f]);
			}
			return sum;
		},
		avgField(rowObj, f)
		{
			let sum = this.sumField(rowObj, f);
			return sum / rowObj.children.length;
		}
	},
	watch:
	{
		'$store.getters.user' (current_user)
		{
			if(current_user.user_id == null)
			{
				return;
			}

			if(this.required_permissions.length == 0)
			{
				return;
			}

			if(!this.userHasAction(current_user, this.required_permissions))
			{
				console.debug("USER DOES NOT HAVE PERMISSION FOR THIS PAGE.");
				console.debug(current_user, this.required_permissions);
				this.$router.push("/").catch(function() { /* ignore error */ });
			}
		}
	}
});


function cleandate(input)
{
	if(input instanceof Date)
	{
		try
		{
			input = input.toISOString();
		}
		catch(e)
		{
			return;
		}
	}

	if(typeof input == "undefined" || input == null || input == "0000-00-00 00:00:00" || input == "0000-00-00")
	{
		return "";
	}

	if(input.length == 10)
	{
		input += " 09:00:00";
	}

	try
	{
		// The first two : are part of the date. For some reason EXIF data uses yyyy:mm:dd hh:mm:ss.
		input = input.replace(" ", "T").replace(/([0-9]{4}):([0-9]{2}):([0-9]{2})T(.*)/, "$1-$2-$3T$4");
		// All dates are UTC. Add Z if not present.
		if(input.charAt(input.length - 1) != "Z")
		{
			input += "Z";
		}
	}
	catch(e)
	{
		return "";
	}

	return input;
}

var long_months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var short_months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

Vue.filter('monthName', function(input)
{
	return long_months[input];
});

Vue.filter('shortDate', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned != "")
	{
		var d = new Date(cleaned);
		return short_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
	}
	else
	{
		return "N/A";
	}
});

Vue.filter('shortDateTime', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned != "")
	{
		var d = new Date(cleaned);
		var hours = d.getHours();
		var minutes = d.getMinutes();
		var ampm = "am";

		if(hours == 0)
		{
			ampm = "am";
			hours = 12;
		}
		else if(hours == 12)
		{
			ampm = "pm";
		}
		else if(hours > 12)
		{
			ampm = "pm";
			hours -= 12;
		}

		// if(hours < 10)
		// {
		// 	hours = "0" + hours;
		// }

		if(minutes < 10)
		{
			minutes = "0" + minutes;
		}

		return short_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear() + " at " + hours + ":" + minutes + ampm;
	}
	else
	{
		return "N/A";
	}
});

Vue.filter('reallyShortDateTime', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned != "")
	{
		var d = new Date(cleaned);
		var hours = d.getHours();
		var minutes = d.getMinutes();
		var ampm = "am";
		var month = d.getMonth() + 1;
		var day = d.getDate();

		if(hours == 0)
		{
			ampm = "am";
			hours = 12;
		}
		else if(hours == 12)
		{
			ampm = "pm";
		}
		else if(hours > 12)
		{
			ampm = "pm";
			hours -= 12;
		}

		if(month < 10)
		{
			month = "0" + month;
		}

		if(day < 10)
		{
			day = "0" + day;
		}

		if(hours < 10)
		{
			hours = "0" + hours;
		}

		if(minutes < 10)
		{
			minutes = "0" + minutes;
		}

		return month + "/" + day + "/" + String(d.getFullYear()).substring(2) + " " + hours + ":" + minutes + ampm;
	}
	else
	{
		return "N/A";
	}
});

Vue.filter('longDate', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned != "")
	{
		var d = new Date(cleaned);
		return long_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
	}
	else
	{
		return "N/A";
	}
});

Vue.filter('longDateTime', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned != "")
	{
		var d = new Date(cleaned);
		var hours = d.getHours();
		var minutes = d.getMinutes();
		var ampm = "am";

		if(hours == 0)
		{
			ampm = "am";
			hours = 12;
		}
		else if(hours == 12)
		{
			ampm = "pm";
		}
		else if(hours > 12)
		{
			ampm = "pm";
			hours -= 12;
		}

		// if(hours < 10)
		// {
		// 	hours = "0" + hours;
		// }

		if(minutes < 10)
		{
			minutes = "0" + minutes;
		}

		return long_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear() + " at " + hours + ":" + minutes + ampm;
	}
	else
	{
		return "N/A";
	}
});

Vue.filter('longTime', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned != "")
	{
		var d = new Date(cleaned);
		var hours = d.getHours();
		var minutes = d.getMinutes();
		var ampm = "am";

		if(hours == 0)
		{
			ampm = "am";
			hours = 12;
		}
		else if(hours == 12)
		{
			ampm = "pm";
		}
		else if(hours > 12)
		{
			ampm = "pm";
			hours -= 12;
		}

		// if(hours < 10)
		// {
		// 	hours = "0" + hours;
		// }

		if(minutes < 10)
		{
			minutes = "0" + minutes;
		}

		return hours + ":" + minutes + ampm;
	}
	else
	{
		return "N/A";
	}
});

Vue.filter('phoneDisplay', function(input)
{
	var value = String(input)
	// var plusone = false;

	if(value.indexOf("+1.") !== false)
	{
		// plusone = true;
		value = value.replace("+1.", "");
	}

	if(value.indexOf("+1") !== false)
	{
		// plusone = true;
		value = value.replace("+1", "");
	}

	value = value.replace(/[^0-9]/g, '');
	var length = value.length;

	if(length == 3)
	{
		// Leave value as-is.
	}
	else if(length <= 6)
	{
		value = value.replace(/([0-9]{3}?)([0-9]{1,3}?)/g, '$1-$2')
	}
	else if(length <= 10)
	{
		value = value.replace(/([0-9]{3}?)([0-9]{3}?)([0-9]{1,4}?)?/g, '$1-$2-$3')
	}
	else
	{
		value = value.replace(/([0-9]{3}?)([0-9]{3}?)([0-9]{4}?)([0-9]{1,5}?)?/g, '$1-$2-$3 x$4')
	}

	return value;
});

Vue.filter('duration', function(seconds, show_seconds)
{
	if(typeof show_seconds == "undefined")
	{
		show_seconds = true;
	}

	if(isNaN(seconds))
	{
		seconds = 0;
	}
	else if(typeof seconds != "number")
	{
		seconds = Number(seconds);
	}

	var days = Math.floor(seconds/60/60/24);
	seconds = Math.floor(seconds - (days*60*60*24))
	var hours = Math.floor(seconds/60/60);
	seconds = Math.floor(seconds - (hours*60*60));
	var minutes = Math.floor(seconds/60);
	seconds = Math.floor(seconds - (minutes*60));

	if(days >= 1)
	{
		return days + "d" + (hours > 0 ? ", " + hours + "h" : "");
	}
	else if(hours >= 1)
	{
		return hours + "h" + (minutes > 0 ? ", " + minutes + "m" : "") + (seconds > 0 && show_seconds ? ", " + seconds + "s" : "");
	}
	else if(minutes >= 1)
	{
		return minutes + "m" + (seconds > 0 && show_seconds ? ", " + seconds + "s" : "");
	}
	else
	{
		return seconds + "s";
	}
});

Vue.filter('relativeDate', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned == "")
	{
		return "N/A";
	}

	if(typeof cleaned == "string")
	{
		input = new Date(cleaned);
	}
	else if(input instanceof Date == false)
	{
		return "";
	}

	var d 		= new Date();
	var notime  = new Date(input);
	// Time is irrelevant here.
	d.setHours(9)
	d.setMinutes(0)
	d.setSeconds(0)
	notime.setHours(9)
	notime.setMinutes(0)
	notime.setSeconds(0)
	var diff 	= (d.getTime() - notime.getTime())/1000;
	var days 	= Math.round(diff/86400);
	var day 	= input.getDay();

	if(days == 0)
	{
		return "Today";
	}
	else if(days == 1)
	{
		return "Yesterday";
	}
	else if(days < 9)
	{
		if(day == 1)
		{
			return "Monday";
		}
		else if(day == 2)
		{
			return "Tuesday";
		}
		else if(day == 3)
		{
			return "Wednesday";
		}
		else if(day == 4)
		{
			return "Thursday";
		}
		else if(day == 5)
		{
			return "Friday";
		}
		else if(day == 6)
		{
			return "Saturday";
		}
		else if(day == 0)
		{
			return "Sunday";
		}
	}
	else
	{
		// Return "long date" format.
		d = new Date(input);
		return long_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
	}
});

Vue.filter('relativeDateCompact', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned == "")
	{
		return "N/A";
	}

	if(typeof cleaned == "string")
	{
		input = new Date(cleaned);
	}
	else if(input instanceof Date == false)
	{
		return "";
	}

	var d 		= new Date();
	var notime  = new Date(input);
	notime.setHours(9)
	notime.setMinutes(0)
	notime.setSeconds(0)
	var diff 	= (notime.getTime() - d.getTime())/1000;
	var days 	= Math.round(diff/86400);
	var day 	= input.getDay();
	var hour 	= input.getHours();
	var current_hour = d.getHours();
	var min 	= input.getMinutes();
	var current_min = d.getMinutes();

	if(days == 0)
	{
		if(current_hour == hour)
		{
			if(current_min > min)
			{
				return "DUE[" + (current_min - min) + " minutes]";
			}

			return (min - current_min) + " minutes";
		}
		else if(current_hour > hour)
		{
			return "DUE[" + (current_hour - hour) + " hours]";
		}

		input = String(input);

		var ampm = "am";

		if(hour >= 12)
		{
			ampm = "pm";
		}
		if(hour > 12)
		{
			hour = hour - 12;
		}

		if(min == 0)
		{
			return "Today at " + hour + ampm;
		}
		else
		{
			return "Today at " + hour + ":" + (min < 10 ? "0" : "") + min + ampm;
		}
	}
	else if(days == -1)
	{
		return "DUE[Yesterday]";
	}
	else if(days == 1)
	{
		return "Tomorrow";
	}
	else if(days > 0 && days < 7)
	{
		if(day == 1)
		{
			return "Monday";
		}
		else if(day == 2)
		{
			return "Tuesday";
		}
		else if(day == 3)
		{
			return "Wednesday";
		}
		else if(day == 4)
		{
			return "Thursday";
		}
		else if(day == 5)
		{
			return "Friday";
		}
		else if(day == 6)
		{
			return "Saturday";
		}
		else if(day == 0)
		{
			return "Sunday";
		}
	}
	else if(days == 7)
	{
		if(day == 1)
		{
			return "Next Monday";
		}
		else if(day == 2)
		{
			return "Next Tuesday";
		}
		else if(day == 3)
		{
			return "Next Wednesday";
		}
		else if(day == 4)
		{
			return "Next Thursday";
		}
		else if(day == 5)
		{
			return "Next Friday";
		}
		else if(day == 6)
		{
			return "Next Saturday";
		}
		else if(day == 0)
		{
			return "Next Sunday";
		}
	}
	else if(days >= 365)
	{
		return "Eventually";
	}
	else if(days >= 330)
	{
		return "11 Months";
	}
	else if(days >= 300)
	{
		return "10 Months";
	}
	else if(days >= 270)
	{
		return "9 Months";
	}
	else if(days >= 240)
	{
		return "8 Months";
	}
	else if(days >= 210)
	{
		return "7 Months";
	}
	else if(days >= 180)
	{
		return "6 Months";
	}
	else if(days >= 150)
	{
		return "5 Months";
	}
	else if(days >= 120)
	{
		return "4 Months";
	}
	else if(days >= 90)
	{
		return "3 Months";
	}
	else if(days >= 60)
	{
		return "2 Months";
	}
	else if(days > 30)
	{
		return "Next Month";
	}
	else if(days > 21)
	{
		return "3 Weeks";
	}
	else if(days > 14)
	{
		return "2 Weeks";
	}
	else if(days > 7)
	{
		return "Next Week";
	}
	else if(days <= -365)
	{
		return "DUE[Eventually]";
	}
	else if(days <= -330)
	{
		return "DUE[11 Months]";
	}
	else if(days <= -300)
	{
		return "DUE[10 Months]";
	}
	else if(days <= -270)
	{
		return "DUE[9 Months]";
	}
	else if(days <= -240)
	{
		return "DUE[8 Months]";
	}
	else if(days <= -210)
	{
		return "DUE[7 Months]";
	}
	else if(days <= -180)
	{
		return "DUE[6 Months]";
	}
	else if(days <= -150)
	{
		return "DUE[5 Months]";
	}
	else if(days <= -120)
	{
		return "DUE[4 Months]";
	}
	else if(days <= -90)
	{
		return "DUE[3 Months]";
	}
	else if(days <= -60)
	{
		return "DUE[2 Months]";
	}
	else if(days <= -30)
	{
		return "DUE[Last Month]";
	}
	else if(days <= -21)
	{
		return "DUE[3 Weeks]";
	}
	else if(days <= -14)
	{
		return "DUE[2 Weeks]";
	}
	else if(days <= -7)
	{
		return "DUE[Last Week]";
	}
	else if(days < 0)
	{
		return "DUE[" + Math.abs(days) + " days]";
	}
	else
	{
		return days + " days";
	}
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
